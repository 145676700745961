import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PortfolioTiles from "./PortfolioTiles"
import PortfolioModal from "./PortfolioModal"
import PortfolioCarousel from "./PortfolioCarousel"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import GradientOverlay from "../Extras/GradientOverlay"

const Portfolio = ({ portfolioRef }) => {
  const imageQuery = useStaticQuery(
    graphql`
      query {
        allCloudinaryMedia(
          filter: { public_id: { glob: "eric-alain/portfolio/*" } }
        ) {
          nodes {
            secure_url
          }
        }
      }
    `
  )
  const images = imageQuery.allCloudinaryMedia.nodes

  const [modal, setModal] = useState({
    show: false,
    current: 0
  })

  const handlePortfolioClick = index => {
    setModal({
      show: true,
      current: index
    })
  }

  const portfolioItems = [
    {
      title: "Shoppi - E-commerce template",
      description: (
        <>
          <p>
            Final project submission for Full-stack Engineer path with
            Codecademy.com.
          </p>
          <p>
            I admit that I didn't do this project exactly to the T of what the
            requirements were (This isn't my first Codecademy path and things
            like express servers, sql dbs aren't new to me). I wanted to play
            with some new things.
          </p>
          <p>
            I've experimented with several React frameworks and other
            technologies and toyed with the idea of making an e-commerce
            template that uses some of my favorite technologies, specifically:
            Remix, Hygraph CMS, Cloudflare pages, as well as other things like
            Zustand for local statement management and persistent session
            management, Firebase for authentication and Stripe for payment
            authorization.
          </p>
          <p>
            I called my concept "Shoppi" and made a galactic themed e-store.
          </p>
          <p>
            I think it has all the required bits for a simple e-comm store:.
          </p>
          <ul>
            <li>Login, sign up and user pages</li>
            <li>
              Other authentication management flows, like, OAuth providers,
              logout, password reset, user account updates, account deletion
            </li>
            <li>
              Product, order history, reviews, leave a review, administrative
              information routes
            </li>
            <li>etc</li>
          </ul>
          <p>
            I've even made a poor man's CRON flow for managing users and cms
            entries automatically, so that my demo site doesn't need to be
            manually maintained.
          </p>
          <p>
            I've spent way more time than I probably should have on this
            project, but it was fun to learn and is the knowledge I gained will
            carry over to my coding journey (I hope lol).
          </p>
          <p>
            <a
              href="https://remix-cloudflare-hygraph-e-commerce.pages.dev"
              target="_blank"
              rel="noreferrer"
            >
              Demo
            </a>
            , <p>Source code is private, for now</p>
          </p>
        </>
      ),
      link: "https://remix-cloudflare-hygraph-e-commerce.pages.dev"
    },
    {
      title: "Theme Machine",
      description: (
        <>
          <p>
            Final project submission for Front-End Engineer path with
            Codecademy.com.
          </p>
          <p>
            The idea is to create an app with all the bells and whistles,
            ranging from routes, user authentication, database management, web
            tooling and continuous CI/CD deployment. I created an app that
            essentially allows users to preview style changes on a live site, in
            real time. They can edit everything pretty liberally, including code
            and everything stays synced up. If the user chooses, they can also
            create an accound to save and load themes as they please.
          </p>
          <p>
            I decided to leverage frameportfolios React and Redux for the
            application development and state management. Gastby and Node for
            bootstrapping and tooling, Tailwind CSS for utility styles, Google
            Firebase for user authentication, storage and database management,
            Github/Netlify for CI/CD and hosting, .
          </p>
          <p>
            I had a blast making this one and plan on using it any time I need
            some inspiration!
          </p>
          <p>
            <a
              href="https://theme-machine.netlify.app"
              target="_blank"
              rel="noreferrer"
            >
              Demo
            </a>
            ,{" "}
            <a
              href="https://github.com/Eric-Alain/theme-machine"
              target="_blank"
              rel="noreferrer"
            >
              Source code
            </a>
          </p>
        </>
      ),
      link: "https://theme-machine.netlify.app"
    },
    {
      title: "Reddit client project",
      description: (
        <>
          <p>
            This is a challenge project from Codecademy.com. The idea is to use
            React and Redux, as well as other front end technologies, to create
            a Reddit-style application by tapping into their API.
          </p>
          <p>
            I decided to leverage frameportfolios like Gastby (build process),
            Github/Netlify (CI/CD and hosting) and React Testing Library/JEST
            (testing).
          </p>
          <p>
            Overall, I just decided to have fun with this one. Don't forget to
            check out the unicorn theme!
          </p>
          <p>
            <a
              href="https://eric-alain-reddit-client-project.netlify.app"
              target="_blank"
              rel="noreferrer"
            >
              Demo
            </a>
            ,{" "}
            <a
              href="https://github.com/Eric-Alain/reddit-client-project"
              target="_blank"
              rel="noreferrer"
            >
              Source code
            </a>
          </p>
        </>
      ),
      link: "https://eric-alain-reddit-client-project.netlify.app"
    },
    {
      title: "Clean lines CMS",
      description: (
        <>
          <p>
            This is a personal challenge. I wanted to create a styled theme for
            a blog-type site using Gatsby and Netlify CMS.
          </p>
          <p>
            The idea is to create a theme that devs and content writers alike
            could download and tailor to their needs. Simple as that.
          </p>
          <p>
            <a
              href="https://clean-lines-cms.netlify.app"
              target="_blank"
              rel="noreferrer"
            >
              Demo
            </a>
            ,{" "}
            <a
              href="https://github.com/Eric-Alain/clean-lines-cms"
              target="_blank"
              rel="noreferrer"
            >
              Source code
            </a>
          </p>
        </>
      ),
      link: "https://clean-lines-cms.netlify.app"
    }
  ]

  return (
    <>
      <section className="page-section" id="portfolio" ref={portfolioRef}>
        <GradientOverlay
          direction="left"
          colorClassName="grad-4"
          opacity="op-50"
        />
        <Container>
          <Row className="justify-content-between">
            <Col xs="12" md="2" className="mb-3 pl-0">
              <h2 className="mb-0">Portfolio</h2>
              <p className="subtitle">Things I've made</p>
              <hr className="d-md-none divider responsive text-start my-4"></hr>
            </Col>
            <Col xs="12" md="10" className="box">
              <sh />
              <PortfolioTiles
                modal={modal}
                handlePortfolioClick={handlePortfolioClick}
                parent={portfolioRef}
                tabIndex="0"
                portfolioItems={portfolioItems}
                images={images}
              />
            </Col>
          </Row>
        </Container>
      </section>

      <PortfolioModal
        show={modal.show}
        onHide={() => setModal({ show: false, current: 0 })}
      >
        <PortfolioCarousel
          current={modal.current}
          images={images}
          portfolioItems={portfolioItems}
        />
      </PortfolioModal>
    </>
  )
}

export default Portfolio
