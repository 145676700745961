import React, { useLayoutEffect, useRef } from "react"
import Col from "react-bootstrap/Col"
import Tab from "react-bootstrap/Tab"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SkillsAnimation } from "../SkillsAnimation"

const Panes = props => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null)
  const ref6 = useRef(null)
  const ref7 = useRef(null)

  useLayoutEffect(() => {
    if (!props.propsKey) return
    switch (props.propsKey) {
      case "full-stack-development":
        SkillsAnimation(ref1.current)
        break
      case "accessibility":
        SkillsAnimation(ref2.current)
        break
      case "react-gatsby":
        SkillsAnimation(ref3.current)
        break
      case "node":
        SkillsAnimation(ref4.current)
        break
      case "stylist":
        SkillsAnimation(ref5.current)
        break
      case "data-visualization":
        SkillsAnimation(ref6.current)
        break
      case "french":
        SkillsAnimation(ref7.current)
        break
      default:
        break
    }
  }, [props.propsKey])

  const skillsPanes = [
    {
      eventKey: "full-stack-development",
      ref: ref1,
      icon: ["fas", "layer-group"],
      heading: "Full stack development",
      text: (
        <>
          <p>
            Proficient in the use of HTML, CSS/SASS, JavaScript/TypeScript, PHP
            & TWIG, JSON/YAML, Markdown, Regular Expressions (REGEX) &amp;
            mySQL/PostgreSQL in order to develop for developing effective and
            charming websites.
          </p>
          <p>
            Proficient in leveraging libraries such as React, Redux and Firebase
            to create RESTful experiences and building them in frameworks like
            Remix, Gatsby or Next.
          </p>
          <p>
            Efficient with Drupal CMS or React-based headless CMS libraries like
            Contentful, Strapi, Sanity, etc. Or connecting frontend to backend
            via using api frameworks like express.
          </p>
          <p>Enthusiastic about responsive, performant and accessible UX.</p>
        </>
      )
    },
    {
      eventKey: "accessibility",
      ref: ref2,
      icon: ["fas", "low-vision"],
      heading: "Accessibility",
      text: (
        <>
          <p>
            Comfortable in meeting the Web Experience Toolkit's (WET) core
            accessibility requirements in order to make web content accessible
            to as many as possible.
          </p>
          <p>
            Knowledgeable in the application of web content accessibility
            guidelines (WCAG) and the web accessibility initiative on accessible
            rich internet applications (WAI-ARIA).
          </p>
          <p>
            Comfortable in the use of accessibility validation methods and
            tools.
          </p>
        </>
      )
    },
    {
      eventKey: "node",
      ref: ref4,
      icon: ["fab", "node"],
      heading: "Node",
      text: (
        <>
          <p>
            Comfortable in the use of Node.js in order to install, update and
            effectively integrate JavaScript modules development libraries,
            tools and APIs into projects.
          </p>
          <p>Comfortable using development libraries & tools such as</p>
          <ul>
            <li>Express.js</li>
            <li>React router</li>
            <li>Webpack</li>
            <li>Gulp.js</li>
            <li>etc</li>
          </ul>
        </>
      )
    },
    {
      eventKey: "stylist",
      ref: ref5,
      icon: ["fas", "wand-magic-sparkles"],
      heading: "Stylist",
      text: (
        <>
          <p>
            Mastery of advanced CSS/SCSS concepts, methodologies and techniques
            to bring applications to life and provide a seamless, accessible and
            responsive experience.
          </p>
          <details class="mb-3">
            <summary>Concepts, methodologies and techniques</summary>
            <ul>
              <li>Grid and flexbox layouts</li>
              <li>Modularity and custom variables</li>
              <li>Pseudo classes, functions, animations and transitions</li>
              <li>Media queries</li>
              <li>Blend modes and filters</li>
              <li>Nested rules, parent references and mixins</li>
              <li>Loops, conditionals, placeholders and extends</li>
              <li>Dynamic theming</li>
              <li>PostCSS processing</li>
            </ul>
          </details>
          <p>
            Well versed in methodologies such as Atomic CSS, BEM and SMACSS.
            Overly concerned with cross-browser compatibility and accessibility.
          </p>
          <p>
            Proficient in the use of utility style libraries such as Bootstrap,
            WET4, Material UI and Tailwind CSS, for developing responsive,
            mobile-first UIs. Comfortable setting up and configuring these
            libraries in React or non-React projects.
          </p>
        </>
      )
    },
    {
      eventKey: "data-visualization",
      ref: ref6,
      icon: ["fas", "chart-area"],
      heading: "Data visualization",
      text: (
        <>
          <p>
            Comfortable in the use of data visualization and table manipulation
            libraries to output dynamic, interactive data visualizations in web
            browsers. Such libraries include D3.js, Recharts.js, Chart.js,
            DataTables.js, etc.
          </p>
          <p>Don't believe me?</p>
          <p>
            Here are a few D3 codepens I've created. Make sure to check out the
            chloropleth map!
          </p>
          <ul>
            <li>
              <a
                href="https://codepen.io/EricAlain/pen/NWpmbQx"
                target="_blank"
                rel="noreferrer"
              >
                Chloropleth map
              </a>
            </li>
            <li>
              <a
                href="https://codepen.io/EricAlain/pen/rNyKdgK"
                target="_blank"
                rel="noreferrer"
              >
                Bar chart
              </a>
            </li>
            <li>
              <a
                href="https://codepen.io/EricAlain/details/RwpBVmJ"
                target="_blank"
                rel="noreferrer"
              >
                Scatterplot graph
              </a>
            </li>
            <li>
              <a
                href="https://codepen.io/EricAlain/details/mdmPeNV"
                target="_blank"
                rel="noreferrer"
              >
                Tree map
              </a>
            </li>
            <li>
              <a
                href="https://codepen.io/EricAlain/pen/qBrQXYK"
                target="_blank"
                rel="noreferrer"
              >
                Heat map
              </a>
            </li>
          </ul>
        </>
      )
    },
    {
      eventKey: "french",
      ref: ref7,
      icon: ["fas", "award"],
      heading: "French",
      text: (
        <>
          <p>
            Je suis parfaitement bilingue dans les deux langues officielles du
            Canada. Je possède des fortes compétences en français ainsi que la
            capacité de les appliquer dans un environnement professionnel.
          </p>
          <p>
            Je possède de l'expérience en communication avec un public français,
            qu'il s'agisse du grand public ou d'intervenants internes.
          </p>
          <p>Sacré bleu!</p>
        </>
      )
    }
  ]

  const renderSkillsPanes = skillsPanes.map((skillsPane, i) => {
    return (
      <Tab.Pane
        key={i}
        eventKey={skillsPane.eventKey}
        ref={skillsPane.ref /*el => (tabPanes.current[i] = el)*/}
      >
        <FontAwesomeIcon icon={skillsPane.icon} className="card-icon" />
        <div className="heading">
          <h3>{skillsPane.heading}</h3>
        </div>
        <div className="text">{skillsPane.text}</div>
      </Tab.Pane>
    )
  })

  return (
    <Col xs="12" sm="10" md="10" lg="11" className="px-0">
      <Tab.Content>
        <>{renderSkillsPanes}</>
      </Tab.Content>
    </Col>
  )
}

export default Panes
