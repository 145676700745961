import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Tab from "react-bootstrap/Tab"
import Icons from "./Icons/Icons"
import Panes from "./Panes/Panes"
import GradientOverlay from "../Extras/GradientOverlay"

const Skills = ({ skillsRef }) => {
  const [key, setKey] = useState("full-stack-development")

  return (
    <section className="page-section" id="skills" ref={skillsRef}>
      <GradientOverlay direction="right" colorClassName="grad-2" opacity="op-60" />
      <Container>
        <Row className="justify-content-between">
          <Col xs="12" md="2" className="mb-3 pl-0">
            <h2 className="mb-0">Skills</h2>
            <p className="subtitle">Things I know</p>
            <hr className="d-md-none divider responsive text-start my-4"></hr>
          </Col>
          <Col xs="12" md="10" className="card box bg-none shadow-lg">
            <sh />
            <Row className="p-2 pt-0">
              <Col xs="12">
                <Tab.Container
                  activeKey={key}
                  onSelect={k => setKey(k)}
                  transition={false}
                  id="skills-tabs"
                  mountOnEnter={true}
                >
                  <Row className="justify-content-between">
                    <Icons />
                    <Panes propsKey={key} />
                  </Row>
                </Tab.Container>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Skills
